.modal-content p{
    padding-bottom: 20px;
    font-size: 16px;
}
.modal-content {
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.close-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.close-button:hover {
    background: #0056b3;
}
